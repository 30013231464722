.App {
  height: 100%;
  width: 100%;
  background-color: #FAFAFA;
}
html {
  height: 100%;
}

body{
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}