.HomeProduct{
    height: 300px;
    margin: 15px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: white;
    padding: 20px;
    min-width: 250px;
    justify-content: space-between;
    align-items: center;
    transition: ease-out 0.3s;
    box-shadow: 9px 9px 11px -5px rgb(0, 0, 0, 0.15);
}



.HomeProductimg{
    height: 80%;
}

@media (max-width: 981px) {
    .HomeProduct{
        height: 300px;
        margin: 20px 0;
        flex-direction: column;
        align-items: center;
    }
}