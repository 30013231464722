
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500&family=Noto+Sans+Arabic:wght@200;300;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600&family=Montserrat:wght@500&family=Noto+Sans+Arabic:wght@200;300;400&display=swap');


body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.sectionTitlearabic{
  margin: 0.5rem 0;
  font-size: 1.7rem;
  font-weight: 700;
  position: relative;
  display: inline-block;
  padding-bottom: 0.6rem;
}
.sectionTitlearabic::after{
  content: "";
  position: absolute;
  right: 0;
  bottom: -2px;
  width: 100px;
  border-radius: 70px;
  height: 4px;
  background: #1245bb;
}