.Article{
    padding: 50px;
    padding-right: 100px;
    margin: 0 auto;
}

.Article-container{
    display: flex;
    margin-top: 100px;

}


@media (max-width: 981px) {
    .Article-container{
        flex-direction: column;
        margin-top: 50px;
    }
    .Article{
        margin: auto;
        padding: 20px;
    }
}