.quality-home-container {
    height: 500px;
    margin: 0 0 50px 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.images-container-quality {
    display: flex;
    width: 100%;
    height: 100%;
    margin-top: 50px;
}

.Backdrop-quality {
    width: 100%;
    height: 100%;
    background-color: rgb(0, 0, 0, 0.4);
    transition: 0.3s ease-out;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    color: white;
}

.english-title {
    text-decoration: none;
    letter-spacing: 10px;
    font-size: 2.5rem;
    font-weight: 300;
}

.arabic-title {
    text-decoration: none;
    letter-spacing: 0px;
    font-size: 3rem;
    font-weight: 300;
}

.Backdrop-quality:hover {
    background-color: rgb(0, 0, 0, 0.2);
}

.left-quality {
    width: 100%;
    height: 100%;
    background-image: url(../../../../public/images/quality-home.webp);
    background-repeat: no-repeat;
    background-size: cover;
}


.right-foodsafe {
    width: 100%;
    height: 100%;
    background-image: url(../../../../public/images/foodsafepackaging-home.webp);
    background-repeat: no-repeat;
    background-size: cover;
}

@media (max-width: 981px) {
    .images-container-quality {
        flex-direction: column;
    }

    .quality-home-container {
        flex-direction: column;
    }

}