.productspage-products-container{
    width: 100%;
    padding: 0 20px;
    margin-top: 50px;
    display: grid;
    grid-template-columns: 30% 30% 30%;
    grid-template-rows: 30% 30% 30%;
    min-height: 100%;
    column-gap: 25px;
    row-gap: 25px;
}
.title-description-container p{
   font-size: 20px;
}
.products-title-images-continer{
    margin: 0 100px;
    display: flex;
}
.products-title-img-flex-container{
    margin: 20px 0 80px 0;
    display: flex;
    justify-content: space-between;
}
.products-title-img{
    width: 100px;

}
.products-page-flex-container{
    display: flex;
    justify-content: space-between;
    margin: 100px;
}
.products-page-container{
    margin: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: fit-content;
    align-self: right;
}

.products-cups-data-container{
    margin: 0 20px;
    direction: rtl;
    width: 70vw;
}


.backbtn{
    text-decoration: none;
    margin: 40px 10px;
    margin-top: 70px;
    padding: 10px 16px;
    min-height: 40px;
    background-color: transparent;
    border: 2px solid rgba(255, 0, 0, 0.747);
    font-weight: bold;
    font-size: 16px;
    border-radius: 3px;
    color: red;
    width: 100px;
    direction: rtl;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
}

.backbtn:hover{
    background-color: rgba(255, 0, 0, 0.747);
    box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
    color: white;
    transform: translateY(-2px);
}

.sidebar-products{
    min-height: 890px!important;
}

@media (max-width: 981px) {
    .productspage-products-container{
        display: flex;
        flex-direction: column;
    }
    .products-flex-container{
        flex-direction: column;
        margin: auto!important;
        width: 95%;
        margin-top: 100px;
    }
    .products-page-flex-container{
        flex-direction: column;
        margin: 100px 0px 0px 0px;
    }
    .products-title-img{
        width: 100px;
        object-fit: contain;
    }
    .products-title-img-flex-container{
        flex-direction: column;
        margin-left: 0!important;
    }
}